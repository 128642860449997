<template>
  <swiper class="swiper" ref="advantagesSwiper" :options="swiperOptions" :auto-update="true">
    <swiper-slide>
      <div class="advantages-slide-block">
        <img src="/assets/images/slide-security.jpg" class="advantages-slide-image" alt="Безпека" />
        <div class="advantages-slide-data">
          <div class="advantages-slide-title">Безпека</div>
          <div class="advantages-slide-content">
            <p>Важливим аспектом для «Gentleman Park» є безпека наших жителів.</p>
            <p>Для забезпечення максимального захисту ми спроектували стильний і затишний будинок закритого типу.</p>
          </div>
          <div class="item-hints">
            <div class="hint" data-position="camera" data-orientation="top-left">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>підключення камер відеоспостереження 24/7</p>
              </div>
            </div>
            <div class="hint" data-position="fence" data-orientation="top-right">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>огороджена територія навколо будинку</p>
              </div>
            </div>
            <div class="hint" data-position="playground" data-orientation="top-right">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>дитячий майданчик тільки для мешканців будинку</p>
              </div>
            </div>
            <div class="hint" data-position="domofon" data-orientation="top-left">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>відеодомофон у кожне житло</p>
              </div>
            </div>
            <div class="hint" data-position="parking" data-orientation="top-left">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>паркінг на території</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="advantages-slide-block">
        <img src="/assets/images/slide-architect.jpg" class="advantages-slide-image" alt="Архітектура" />
        <div class="advantages-slide-data">
          <div class="advantages-slide-title">Архітектура</div>
          <div class="advantages-slide-content">
            <p>Завдяки вдалим архітектурним рішенням ми зробили помешкання не лише вишуканим та комфортним, а й енергоефективним.</p>
          </div>
          <div class="item-hints">
            <div class="hint" data-position="windows" data-orientation="top-left">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>енергоощадний 6-ти камерний віконний профіль</p>
              </div>
            </div>
            <div class="hint" data-position="walls" data-orientation="top-left">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>стіни із цегли та утеплювачу з пінопласту й клінкерної плитки</p>
              </div>
            </div>
            <div class="hint" data-position="individual" data-orientation="top-right">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>з індивідуальним опаленням ви заощаджуватимете в зимовий період та самостійно контролюватимете подачу тепла у ваше помешкання.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="advantages-slide-block">
        <img src="/assets/images/slide-lobby.jpg" class="advantages-slide-image" alt="Комфорт" />
        <div class="advantages-slide-data">
          <div class="advantages-slide-title">Комфорт</div>
          <div class="advantages-slide-content">
            <p>Змінюємо свідомість - кажемо "НІ" бетонним, сірим та холодним під'їздам.</p>
            <p>М'який куточок для ваших гостей для комфортного їх очікування.</p>
          </div>
          <div class="item-hints">
            <div class="hint" data-position="lobby" data-orientation="top-left">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>дизайнерське лобі зі стильними м'якими меблями</p>
              </div>
            </div>
            <div class="hint" data-position="lift" data-orientation="top-right">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>безшумний ліфт</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="advantages-slide-block">
        <img src="/assets/images/slide-terasse.jpg" class="advantages-slide-image" alt="Тераса" />
        <div class="advantages-slide-data">
          <div class="advantages-slide-title">Тераса</div>
          <div class="advantages-slide-content">
            <p>Ми знаємо, як важливо тримати баланс внутрішньої гармонії та міської метушні.</p>
            <p>Саме для цього спроєктована зелена зона відпочинку - ваш свідомий вибір бути щасливим.</p>
          </div>
          <div class="item-hints">
            <div class="hint" data-position="terasse" data-orientation="top-right">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>відпочинкова зона понад 200 кв.м. на даху будинку для мешканців</p>
              </div>
            </div>
            <div class="hint" data-position="green" data-orientation="top-right">
              <span class="hint-radius"></span>
              <span class="hint-dot"></span>
              <div class="hint-content do--split-children">
                <p>приватна зелена територія, викладена гранітною бруківкою.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>
<script>
  // Import Swiper styles
  export default {
    name: 'swip',
    data() {
      return {
        swiperOptions: {
          autoHeight: true, //enable auto height
          slidesPerView: 1.33,
          centeredSlides: true,
          spaceBetween: 15,
          breakpoints: {
            '480': {
              // slidesPerView: 4,
              // spaceBetween: 40
            },
            '640': {
              // slidesPerView: 5,
              spaceBetween: 100
            },
          },
          // slidesPerGroup: 3,
          loop: true,
          grabCursor: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
      }
    },
    computed: {
      swiper() {
        return this.$refs.advantagesSwiper.$swiper
      }
    },
    mounted() {
      console.log('Current Swiper instance object', this.swiper)
      this.swiper.slideTo(1, 1000, false)
    }
  };
</script>
