import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/plan',
    component: () => import('../views/Plan.vue')
  },
  {
    path: '/box',
    component: () => import('../views/Box.vue')
  },
  {
    path: '/plan/floor/:floor',
    component: () => import('../views/PlanFloor.vue'),
    props: true
  },
  {
    path: '/plan/floor/:floor/apartment/:apartment',
    component: () => import('../views/PlanApartment.vue'),
    props: true
  },
  {
    path: '/news',
    component: () => import('../views/News.vue')
  },
  {
    path: '/news/:slug',
    component: () => import('../views/NewsRead.vue'),
    props: true
  },
  {
    path: '/3d',
    component: () => import('../views/Tour.vue')
  },
  {
    path: '/gallery',
    component: () => import('../views/GalleryMain.vue')
  },
  {
    path: '/gallery/:id',
    component: () => import('../views/Gallery.vue'),
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/NotFound.vue'),
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
