<template>
  <div id="app" v-bind:class="containerClass">
    <router-view/>
    <HeaderMenu/>
    <MobileMenu/>
    <LeftContactsMenu/>
    <RightContactsMenu/>
  </div>
</template>

<script>
import HeaderMenu from '@/components/HeaderMenu'
import MobileMenu from '@/components/MobileMenu'
import LeftContactsMenu from '@/components/LeftContactsMenu'
import RightContactsMenu from '@/components/RightContactsMenu'

export default {
  name: 'App',
  data() {
    return {
      containerClass: 'home',
    }
  },
  components: {
    HeaderMenu: HeaderMenu,
    MobileMenu: MobileMenu,
    LeftContactsMenu,
    RightContactsMenu,
  },
  metaInfo() {
      return {
          title: "🏠 ЖБК GENTLEMAN park - Досконалість у кожному будинку",
          meta: [
              { name: 'description', content:  '▶️ Gentleman Park - це команда молодих і активних спеціалістів з сучасним поглядом на прості речі. Ми будуємо елітні будинки з витонченим дизайном, разом з тим ми цінуємо комфорт і практичність створюваного простору. Ми прагнемо зробити життя у нашому місті стильним та затишним ☎ +38 098 099 0707'},
              { property: 'og:title', content: "🏠 ЖБК GENTLEMAN park - Досконалість у кожному будинку"},
              { property: 'og:description', content: "▶️ Gentleman Park - це команда молодих і активних спеціалістів з сучасним поглядом на прості речі. Ми будуємо елітні будинки з витонченим дизайном, разом з тим ми цінуємо комфорт і практичність створюваного простору. Ми прагнемо зробити життя у нашому місті стильним та затишним ☎ +38 098 099 0707"},
              { property: 'og:site_name', content: '➊ Житловий комплекс Джентльмен парк'},
              { property: 'og:image', content: 'https://gentleman-park.com.ua/icons/og-cover-main.jpg'},
              {property: 'og:type', content: 'business.business'}
              // {name: 'robots', content: 'index,follow'}
          ]
      }
  }
}
</script>

<style lang="scss">
@import '../node_modules/bootstrap/scss/bootstrap.scss';
// @import '../../../node_modules/fullpage.js/dist/fullpage.min.css';
@import '../node_modules/swiper/swiper.scss';
@import '@/assets/scss/style';
// import 'swiper/swiper-bundle.css'

</style>
