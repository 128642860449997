<template>
  <div class="home">
    <div class="about-slide-background"
      data-background-image-srcset="
      /assets/images/bg-slide-info-hd.jpg 1280w,
      /assets/images/bg-slide-info-wxga.jpg 1360w,
      /assets/images/bg-slide-info-1440.jpg 1440w,
      /assets/images/bg-slide-info-1536.jpg 1536w,
      /assets/images/bg-slide-info-fhd-night.jpg 1920w,
      /assets/images/bg-slide-info-4k.jpg 3840w">
    </div>
    <full-page :options="options" id="fullpage">
        <div class="section" id="section-home" data-anchor="home"
          data-background-image-srcset="
          /assets/images/bg-slide-home-hd.jpg 1280w,
          /assets/images/bg-slide-home-wxga.jpg 1360w,
          /assets/images/bg-slide-home-1440.jpg 1440w,
          /assets/images/bg-slide-home-1536.jpg 1536w,
          /assets/images/bg-slide-home-fhd.jpg 1920w,
          /assets/images/bg-slide-home-4k.jpg 3840w">
          <div class="container">
            <h2>Досконалість в кожному будинку</h2>
          </div>
        </div>
        <div class="section" id="section-about" data-anchor="about">
          <AboutSlide/>
        </div>
        <div class="section" id="section-info" data-anchor="info">
          <InfoSlide/>
        </div>
        <div class="section" id="section-advantages" data-anchor="advantages">
          <!-- <div class="container-fluid"> -->
          <!-- Slider main container -->
          <AdvantagesSlide></AdvantagesSlide>
        </div>
        <div class="section" id="section-locations" data-anchor="locations">
          <LocationsSlide></LocationsSlide>
        </div>
        <div class="section" id="section-news" data-anchor="news">
          <NewsSlide></NewsSlide>
        </div>
        <div class="section" id="section-contacts" data-anchor="contacts">
          <ContactsSlide></ContactsSlide>
        </div>
    </full-page>
  </div>
</template>

<script>
// import {AboutSlide, InfoSlide, AdvantagesSlide} from '@/views/home-sections'
import AboutSlide from '@/views/home-sections/AboutSlide'
import InfoSlide from '@/views/home-sections/InfoSlide'
import AdvantagesSlide from '@/views/home-sections/AdvantagesSlide'
import LocationsSlide from '@/views/home-sections/LocationsSlide'
import NewsSlide from '@/views/home-sections/NewsSlide'
import ContactsSlide from '@/views/home-sections/ContactsSlide'

export default {
  name: 'Home',
  components: {
    AboutSlide, InfoSlide, AdvantagesSlide, LocationsSlide, NewsSlide, ContactsSlide
  },
  data() {
    return {
      options: {
        licenseKey: '02AD849D-157D4E03-A7214D08-A242BB8A',
        // navigation: true,
        showActiveTooltip: true,
        anchors:['home'],
        sectionsColor:['#9b9ea7', 'transparent', 'transparent', '#ffffff', '#ffffff', '#ffffff', '#ffffff']
      },
    }
  },
  // beforeCreate: function() {
  //     document.body.className = 'fp-viewing-home';
  // }
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    document.documentElement.style.setProperty('--height-of-info-block-mobile', document.querySelector('.section-info-container').offsetHeight + "px");
    document.documentElement.style.setProperty('--height-of-contacts-block-mobile', document.querySelector('.section-contacts-container').offsetHeight + "px");

    document.body.classList.add('home-slider-page')
  },
  destroyed () {
    document.body.classList.remove('home-slider-page')
  }
}
</script>
