<template>
  <div class="mobile-menu d-sm-none" v-bind:class="{ 'expanded-menu': isExpanded }">
    <div class="hamburger-menu-wrapper">
      <div class="hamburger-menu" @click="toggleHamburger($event)">

      </div>
    </div>
    <div class="mobile-menu-area">
      <router-link to="/" class="menu-item" @click.native="toggleHamburger">Головна</router-link>
      <router-link to="/plan" class="menu-item" @click.native="toggleHamburger">Планування</router-link>
      <router-link to="/gallery" class="menu-item" @click.native="toggleHamburger">Галерея</router-link>
      <router-link to="/news" class="menu-item" @click.native="toggleHamburger">Новини</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: false,
    }
  },
  methods: {
    toggleHamburger(event) {
      console.log(event);
      this.isExpanded = !this.isExpanded
      event.currentTarget.classList.toggle('animate')
      // return console.log(floor);
      // return this.$router.push('gallery');
    }
  }
}
</script>

<style lang="scss">
.mobile-menu {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
  height: 0;
  .mobile-menu-area {
    height: 100vh;
    width: 100%;
    background: #fff;
    padding-top: 200px;
    transform: translateX(-100vw);
    transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    .menu-item {
      display: block;
      width: 100%;
      font-weight: 700;
      font-size: 2em;
      padding-top: 0.5em;
      padding-left: 1em;
    }
  }
  &.expanded-menu {
    .mobile-menu-area {
      transform: translateX(0vw);
    }
  }
}

$bar-width: 36px;
$bar-height: 4px;
$bar-spacing: 12px;

.hamburger-menu-wrapper {
	position: absolute;
  top: $bar-spacing + (2 * $bar-height) + 30px;
  right: 15px;
  bottom: 0;
  margin: auto;
  width: $bar-width;
	height: $bar-height + $bar-spacing*2;
	cursor: pointer;
  z-index: 11;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
	height: $bar-height;
}

.hamburger-menu {
	position: relative;
	transform: translateY($bar-spacing);
	background: rgba(0, 0, 0, 1);
	transition: all 0ms 300ms;

  &.animate {
    background: rgba(0, 0, 0, 0);
  }
}

.hamburger-menu:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: $bar-spacing;
	background: rgba(0, 0, 0, 1);
	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
	content: "";
	position: absolute;
	left: 0;
	top: $bar-spacing;
	background: rgba(0, 0, 0, 1);
	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
	top: 0;
	transform: rotate(45deg);
	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

.hamburger-menu.animate:before {
	bottom: 0;
	transform: rotate(-45deg);
	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}
</style>
