<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <h3>Новини</h3>

        <div v-for="(newsItem, newsIndex) in $news.slice(0, 2)" :key="newsIndex">
          <NewsItem :newsItem="newsItem" />
        </div>
        <div class="show-more-area"><router-link to="/news" class="show-more-link">Показати ще <i class="fas fa-level-down-alt"></i></router-link></div>
      </div>
      <div class="col-sm-6">
        <h3>Хід будівництва</h3>
        <a href="https://instagram.com/gentleman.park" target="_blank"><img data-src="/assets/images/hid-zagl.jpg" class="news-zagl py-1 py-md-3" /></a>
        <div class="row">
          <div class="col-4"><a class="construction-progress-link" href="#">Серпень 2021</a></div>
          <div class="col-4"><a class="construction-progress-link" href="#">Травень 2021</a></div>
          <div class="col-4"><a class="construction-progress-link" href="#">Березень 2021</a></div>
          <div class="col-4"><a class="construction-progress-link" href="#">Грудень 2020</a></div>
          <div class="col-4"><a class="construction-progress-link" href="#">Жовтень 2020</a></div>
          <div class="col-4"><a class="construction-progress-link" href="#">Серпень 2020</a></div>
        </div>
        <div class="show-more-area"><a class="show-more-link" href="#">Показати ще <i class="fas fa-level-down-alt"></i></a></div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsItem from '@/components/NewsItem'

export default {
  components: {
    NewsItem
  }
}
</script>
