<template>
  <div>
    <div class="container">
      <h3>Розташування</h3>
    </div>
    <div class="container-fluid py-1 py-md-3">
      <div class="row">
        <GmapMap
          :center="{lat: 49.559825436891224, lng: 25.59693042920364}"
          :zoom="16"
          map-type-id="terrain"
          class="googleMap"
        >
          <GmapMarker ref="myMarker"
            :position="google && new google.maps.LatLng(49.559825436891224, 25.59693042920364)" />
          <!-- <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center=m.position"
          /> -->
        </GmapMap>
        <!-- <div id="googleMap" class="googleMap"></div> -->
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-6 col-sm py-1 py-md-3">
          <div class="infrastructure-data-value">8<sub>xв</sub></div>
          Паркова зона (600 м)
        </div>
        <div class="col-6 col-sm py-1 py-md-3">
          <div class="infrastructure-data-value">10<sub>xв</sub></div>
          Театральний майдан (800 м)
        </div>
        <div class="col-6 col-sm py-1 py-md-3">
          <div class="infrastructure-data-value">3<sub>xв</sub></div>
          Дитячий садочок (350 м)
        </div>
        <div class="col-6 col-sm py-1 py-md-3">
          <div class="infrastructure-data-value">7<sub>xв</sub></div>
          Ресторан (500 м)
        </div>
      <div class="row">
      </div>
        <div class="col-6 col-sm py-1 py-md-3">
          <div class="infrastructure-data-value">3<sub>xв</sub></div>
          Транспортна зупинка (200 м)
        </div>
        <div class="col-6 col-sm py-1 py-md-3">
          <div class="infrastructure-data-value">12<sub>xв</sub></div>
          Цілодобовий супермаркет (1200 м)
        </div>
        <div class="col-6 col-sm py-1 py-md-3">
          <div class="infrastructure-data-value">6<sub>xв</sub></div>
          Спортивний комплекс (450 м)
        </div>
        <div class="col-6 col-sm py-1 py-md-3">
          <div class="infrastructure-data-value">8<sub>xв</sub></div>
          Школа (550 м)
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {gmapApi} from 'vue2-google-maps'

export default {
  name: 'LocationsSlide',
  computed: {
    google: gmapApi
  }
  // beforeCreate: function() {
  //     document.body.className = 'fp-viewing-home';
  // }
}
</script>
