<template>
  <router-link :to="'news/' + newsItem.slug" class="card news-card mt-1 mt-md-3">
    <div class="row g-0">
      <div class="col-3 col-md-5">
        <img :src="newsItem.thumbnail" class="news-cover" alt="Початок продаж">
      </div>
      <div class="col-9 col-md-7">
        <div class="card-body">
          <h5 class="card-title">{{ newsItem.title }}</h5>
          <p class="card-text">{{ newsItem.shorttext | cutText(120) }}</p>
          <p class="card-text"><small class="text-muted">{{ newsItem.date }}</small></p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>

export default {
  props: ['newsItem'],
  filters: {
    cutText(value, symbolsCount) {
      return value.length > symbolsCount
        ? value.slice(0, symbolsCount - 3) + '...'
        : value;
    }
  }
}
</script>
